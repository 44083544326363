import Config from '../Config'
var SinkConfig = {
    scale : 0.001,
    tableWidth: 1200,
    sinkWidth: 600,
    minSinkWidth: 400,
    maxSinkWidth: 2900, 
    minTableWidth: 600,
    maxTableWidth: 3100,
    tableHeight: 400,
    beckenHoehe: 80,
    beckenTiefe: 250,
    tableDepth: 230,
    sinkPos: 600,
    outerBorder: 100,
    innerBorder: 200,
    sinkSteps : [400,500,600,800,1000,1200],
    sinkMontage: 1,
    singleSink: 1,
    sinkType: 0, 
    withUnderTable: false,
    materialid: 117,
    materialimage: Config.ServerURL + "/upload/static/upload/GG_Chroma.jpg",
    materialname: "VETRITE GG Chroma",
    materialglossy:true,
    baseprice: 850,
    aufschlag: 0.53846,
    doppelaufschlag: 150,
    auflagenpreis: 59,
    anzahlauflagen: 0,
    endpreis: 0,
    endpreisfloat: 0.0,
    basendpreis: 0,
    baseendpreisfloat: 0.0,
    thumbnail: Config.ServerURL + "/upload/static/upload/002.jpg.128x128_q85_crop.jpg",
    dealerMargin: 53.846,
    ablagenpreis: "861.54 €",
    ablagenpreisfloat: 861.54,
    
}

export default SinkConfig
