import React from "react";
import Tabui from "./Tab";
import Requestform from "./Requestform"
import PDF from "./PDF"
import PreviewBox from "./PreviewBox"
//import {SCENE} from './Scene'

const Ui = (props) => {
const version = process.env.REACT_APP_VERSION
const [imgurl, setImgURL] = React.useState("")
const [currentMaterial, setCurrentMaterial] = React.useState([]);
  return (
    <div>
	<img src="/sicis.png" />
      <Tabui imgurl={imgurl} setImgURL={setImgURL}  setCurrentMaterial={setCurrentMaterial} currentMaterial={currentMaterial}/>
      <br />  
      
            <Requestform screenshot={props.screenshot} setScreenshot={props.setScreenshot}/>
            <PDF screenshot={props.screenshot} setScreenshot={props.setScreenshot} currentMaterial={currentMaterial}/>
            <div className="or" data-text="/"></div>
      <br/><br/>
      <div className="ui bottom attached label"><small>SICIS Waschtisch-Konfigurator v.{version}</small></div>
    </div>
  );
};

export default Ui;
